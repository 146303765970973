import auth, { AuthStore } from "./modules/auth";
import data, { DataStore } from "./modules/data";
import education, { EducationStore } from "./modules/education";
import message, { MessageStore } from "./modules/message";
import ui, { UiStore } from "./modules/ui";

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export interface State {
  auth: AuthStore;
  ui: UiStore;
  message: MessageStore;
  data: DataStore;
  education: EducationStore;
  initialized: boolean;
}

export default new Vuex.Store({
  state: {
    initialized: true,
  },
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    ui,
    auth,
    message,
    data,
    education
  },
});
