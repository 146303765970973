
import gql from "graphql-tag";
import { bind } from "lodash";
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component
export default class FileDrop extends Vue {
  @Prop()
  labelKey;
  files = [];

  getLabel(){
    return this.$t(this.labelKey);
  }

  handleFileDrop(e) {
    const droppedFiles = e.dataTransfer.files;
    if (!droppedFiles) return;
    [...droppedFiles].forEach(f => {
      this.files.push(f);
    });
    const file = this.files[0];
    this.handleFile(file);
  }
  handleFileInput(e) {
    const file = e.target.files[0];
    this.handleFile(file);
  }

  onDrop(e) {
    const dragevent = e as DragEvent;
    if (dragevent.dataTransfer) {
      this.handleFile(dragevent.dataTransfer.files[0]);
    }
  }

  handleFile(file) {
    if (file.size < 2000000) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        this.$emit("file-string", reader.result);
        this.$emit("file-name", file.name);
      }.bind(this);
      reader.onerror = function(error) {
        console.error("Error: ", error);
      };
    } else {
      alert("file troppo grande");
    }
  }
}
