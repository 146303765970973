const enTerms = {
  onboardingTermsProject: 'Versione Premium di h.whead. Inizia a lavorare su un progetto: cambio ruolo e sottoscrizione di un abbonamento',
  onboardingTermsProjectContent: `<p>Nel caso in cui dovessi stipulare un accordo di collaborazione professionale con Whead cambierai ruolo diventando:
                                                                                          <ul>
                                                                                                                          <li>Costruttore: professionista operativo (es. developer);</li>
                                                                                                                          <li>Guida: project manager;</li>
                                                                                                                          <li>o altro ruolo di categoria superiore.</li>
                                                                                          </ul>
                                                          </p>
                                                          <p>Ti sarà quindi richiesta la sottoscrizione dell’abbonamento Premium di h.whead (il prezzo ti sarà comunicato dentro la piattaforma e sarai sempre libero di accettare o rifiutare senza sostenere nessun costo).</p>`,
  onboardingTermsSubtitle: 'Informazioni sui servizi e le opportunità che ti offriamo per soddisfare al meglio le tue esigenze.',
  onboardingTermsUsersRole: 'Versione Free di h.whead. Entra e scopri come crescere di posizione in Whead',
  onboardingTermsUsersRoleContent: `<p>Versione Free di h.whead: Utente Potenziale e Utente Osservatore</p>
                                                          <p>La versione gratuita di h.whead è prevista per gli utenti Potenziali e Osservatori.</p>
                                                          <p>UTENTE POTENZIALE</p>
                                                          <p>L’utente potenziale è colui che prende parte al processo di recruiting e attende l’esito positivo o negativo di ammissione al database dei professionisti di Whead.</p>
                                                          <p>Prendendo parte al processo di recruiting: <br />
                                                                                          <ul>
                                                                                                                          <li>nessuna tariffa per l’uso della piattaforma;</li>
                                                                                                                          <li>diventi un Wheader Osservatore (professionista iscritto al database di Whead) solo se l’esito del processo di recruiting è positivo;</li>
                                                                                                                          <li>ti impegni a compilare il tuo profilo;</li>
                                                                                                                          <li>ti impegni a rispondere ad un questionario inerente le tue competenze.</li>
                                                                                          </ul>
                                                          </p>
                                                          <p>UTENTE OSSERVATORE</p>
                                                          <p>Con esito positivo del recruiting sei un Utente Osservatore: <br />
                                                                                          <ul>
                                                                                                                          <li>nessuna tariffa per l’uso della piattaforma;</li>
                                                                                                                          <li>diventi un Wheader (professionista iscritto al database di Whead);</li>
                                                                                                                          <li>opportunità di essere contattato per lavorare sui progetti di Whead;</li>
                                                                                                                          <li>opportunità di essere segnalato ad aziende in cerca di professionisti;</li>
                                                                                                                          <li>opportunità di proporre a Whead un progetto di un tuo cliente che non riesci a realizzare da solo;</li>
                                                                                                                          <li>puoi smettere di usare il servizio h.whead quando vuoi senza nessun costo di interruzione.</li>
                                                                                          </ul>
                                                          </p>
                                                          <p>Con esito negativo del recruiting il tuo account sarà prima sospeso e poi eliminato.</p>`,
  onboardingTermsWheadPlatform: 'h.whead: un nuovo modo di lavorare',
  onboardingTermsWheadPlatformContent: `<p>h.whead.eu è una piattaforma digitale che riunisce figure professionali disponibili ad essere incaricate dello sviluppo ed esecuzione di progetti di vario tipo in ambito digitale in favore di aziende e agenzie.</p>
                                                              <p>Whead gestisce la relazione col Cliente, segnala ai Clienti i Wheader in linea con le richieste di progetto, coordina e supervisiona il lavoro dei Wheader, anche in forma automatizzata e interviene direttamente nella risoluzione di eventuali controversie tra gli Utenti, assumendosi la titolarità ed il rischio di consegna del progetto.</p>`
}

export default enTerms;
