
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Card from "@/components/Card.vue";
import Btn from "@/components/Btn.vue";

@Component({
  components: { Card, Btn },
})
export default class Modal extends Vue {
  @Prop()
  title: string;
  @Prop()
  subtitle: string;
  @Prop()
  subtitleExtension: string;
  @Prop()
  showClose: boolean;
  @Prop()
  showActions: boolean;
  @Prop()
  positiveButton: string;
  @Prop({ default: false })
  disablePositiveButton: boolean;
  @Prop({ default: false })
  loadingPositiveButton: boolean;
  @Prop()
  negativeButton: string;

  onClose() {
    this.$emit("close");
  }
}
