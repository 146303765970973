
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Card extends Vue {
  @Prop()
  removePadLeft: boolean;
  @Prop()
  removePadTop: boolean;
  @Prop()
  removePadRight: boolean;
  @Prop()
  removePadBottom: boolean;
  @Prop()
  showHeader: boolean;
  @Prop()
  headerTitle: boolean;
}
