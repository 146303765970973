import * as Api from "@/types/graphql";

import { CourseAvailability, CourseStatus } from "@/utils/courses";
import { RoleCodes, checkUserRole } from "@/utils/roles";
import router, { RoutesNames } from "@/router";

import { Auth } from "aws-amplify";
import _ from "lodash";
import { apolloClient } from "@/main";
import gql from "graphql-tag";
import { onLogout } from "@/vue-apollo";

export interface AuthStore {
  userIsLoggedIn: boolean;
  user: Api.User | {};
  rolesList: Api.Role[];
  statusList: Api.UserStatus[];
  userHash: string;
}

export const state: AuthStore = {
  userIsLoggedIn: false,
  user: {},
  rolesList: [],
  statusList: [],
  userHash: "",
};

export const getters = {
  getRole: (state) => (code) => {
    return _.find(state.rolesList, (role) => {
      return role.role_id == code;
    });
  },
  getStatus: (state) => (code) => {
    return _.find(state.statusList, (status) => {
      return status.code == code;
    });
  },
  checkUserStatus: (state) => (code) => {
    return state.user.status.code == code;
  },
  checkUserRole: (state) => (roleCodes: RoleCodes[]) => {
    return checkUserRole(state.user, roleCodes);
  },
};

export const mutations = {
  setUser(state: AuthStore, user: Api.User) {
    state.user = user;
  },
  setUserHash(state: AuthStore, userHash: string) {
    state.userHash = userHash;
  },
  setRolesList(state: AuthStore, rolesList: Api.Role[]) {
    state.rolesList = rolesList;
  },
  setStatusList(state: AuthStore, statusList: Api.UserStatus[]) {
    state.statusList = _.uniqBy(_.concat(state.statusList, statusList), "guid");
  },
  login(state: AuthStore) {
    state.userIsLoggedIn = true;
  },
  logout(state: AuthStore) {
    state.userIsLoggedIn = false;
  },
};

export const actions = {
  async login(context, payload) {
    try {
      const user = await Auth.signIn(payload.email, payload.password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        router.push({
          name: RoutesNames.setPassword,
          params: { type: "current" },
          query: { email: payload.email },
        });
      } else if (user) {
        const session = await Auth.currentSession();
        const res = await apolloClient.query({
          query: require("@/graphql/loginByCognitoToken.graphql"),
          fetchPolicy: "no-cache",
          variables: {
            token: session.getIdToken().getJwtToken(),
          },
        });
        const users = res.data.LoginByCognito as Api.User[];
        if (users.length > 0) {
          const user = users[0];
          context.commit("setUser", user);
          context.commit("login");

          const courses = await context.dispatch("getCoursesByStudent", {
            userGuid: user.guid,
            availability: [CourseAvailability.Public, CourseAvailability.Private],
            status: [CourseStatus.Published],
          });

          if(courses.length > 0){
            router.push({ name: RoutesNames.nextLessons });
          } else {
            router.push({ name: RoutesNames.profile });
          }
        }
      }
    } catch (error) {
      context.dispatch("handleCognitoError", error);
    }
  },
  async setNewPassword(context, payload) {
    if (payload.type == "current") {
      Auth.signIn(payload.email, payload.password)
        .then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(user, payload.newPassword, {
              email: payload.email,
            })
              .then((user) => {
                context.commit(
                  "showSuccess",
                  "Password impostata correttamente - Adesso puoi accedere"
                );
                context.dispatch("login", {
                  email: payload.email,
                  password: payload.newPassword,
                });
              })
              .catch((error) => {
                console.log(error);
                context.dispatch("handleCognitoError", error);
              });
          } else {
            context.commit("showError", "Errore - Errore Login, riprovare");
          }
        })
        .catch((error) => {
          context.dispatch("handleCognitoError", error);
        });
    }
    if (payload.type == "code") {
      Auth.forgotPasswordSubmit(
        payload.email,
        payload.code,
        payload.newPassword
      )
        .then((user) => {
          context.commit("showSuccess", "Password impostata correttamente");
          context.dispatch("login", {
            email: payload.email,
            password: payload.newPassword,
          });
        })
        .catch((error) => {
          console.log(error);
          context.dispatch("handleCognitoError", error);
        });
    }
  },
  async forgotPassword(context, payload) {
    Auth.forgotPassword(payload.email)
      .then((data) => {
        context.commit("showSuccess", "Password reset completato");
        router.push({
          name: RoutesNames.setPassword,
          params: { type: "code" },
          query: { email: payload.email },
        });
      })
      .catch((error) => {
        context.dispatch("handleCognitoError", error);
      });
  },
  async getUser(context) {
    try {
      const res = await apolloClient.query({
        query: require("@/graphql/getUser.graphql"),
        fetchPolicy: "no-cache",
      });
      const user = res.data.UserByToken as Api.User;
      //console.log('user guid', user.guid)
      await context.commit("setUser", user);
      await context.commit("login");
      // }
    } catch (error) {
      console.error(error);
    }
  },

  async logout(context) {
    try {
      await onLogout(apolloClient);
      await Auth.signOut();
      context.commit("setUser", {});
      context.commit("logout");
      router.push("/");
    } catch (e) {
      console.error();
    }
  },
  async fetchRolesList(context) {
    if (_.isEmpty(context.state.rolesList)) {
      try {
        const res = await apolloClient.query({
          query: gql`
            query {
              RoleList {
                guid
                des
                role_id
              }
            }
          `,
        });
        context.commit("setRolesList", res.data.RoleList);
      } catch (e) {
        console.error(e);
      }
    }
  },
  async fetchStatusList(context, roleCode) {
    if (_.isEmpty(context.state.rolesList)) {
      await context.dispatch("fetchRolesList");
    }
    try {
      let role = ""; // it means get all status values
      if (!_.isEmpty(roleCode)) {
        role = context.getters.getRole(roleCode);
      }
      const res = await apolloClient.query({
        query: gql`
          query($roleGuid: String) {
            UserStatusList(role_guid: $roleGuid) {
              guid
              des
              code
            }
          }
        `,
        variables: { roleGuid: _.isEmpty(roleCode) ? role : role["guid"] },
      });
      context.commit("setStatusList", res.data.UserStatusList);
    } catch (e) {
      console.error(e);
    }
  },
  async setUserStatus(context, statusCode) {
    if (_.isEmpty(context.state.statusList)) {
      await context.dispatch("fetchStatusList", context.state.user.role.guid);
    }
    try {
      const res = await apolloClient.mutate({
        mutation: require("@/graphql/setUserStatus.graphql"),
        variables: {
          guid: context.state.user.guid,
          role: context.state.user.role.guid,
          status: context.getters.getStatus(statusCode).guid,
        },
      });
      if (res.data.changeStatusUser) {
        context.state.user.status = context.getters.getStatus(statusCode);
      }
    } catch (e) {
      console.error();
    }
  },
  handleCognitoError(context, error) {
    console.log(error);
    switch (error.code) {
      case "UserNotFoundException":
        context.commit("showError", "Errore Login - Utente inesistente");
        break;
      case "NotAuthorizedException":
        context.commit(
          "showError",
          "Errore Login - Email o password incorretti"
        );
        break;
      case "CodeMismatchException":
        context.commit("showError", "Il codice di verifica non è corretto");
        break;
      case "LimitExceededException":
        context.commit("showError", "Errore Generico");
        break;
    }
  },
  async checkTerms(context) {
    try {
      const termsRes = await apolloClient.query({
        query: gql`
          query {
            TermCondition {
              guid
              accepted
              title
              content
            }
          }
        `,
        fetchPolicy: "no-cache",
      });
      if (_.isEmpty(termsRes.data.TermCondition)) {
        context.commit(
          "showError",
          "Impossibile recuperare i Termini e Condizioni"
        );
        await context.dispatch("logout");
      } else {
        return termsRes.data.TermCondition[0].accepted == 1;
      }
    } catch (e) {
      context.commit(
        "showError",
        "Impossibile recuperare i Termini e Condizioni"
      );
      await context.dispatch("logout");
      console.error();
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
