
import Component from "vue-class-component";
import Vue from "vue";
import Modal from "@/components/Modal.vue";
import TermsModalContent from "@/components/TermsModalContent.vue";

@Component({
  components: {
    Modal,
    TermsModalContent
  },
})
export default class Footer extends Vue {
  termsModal = false;

  closeModal() {
    this.termsModal = false;
  }
}
