import * as Api from "@/types/graphql";

import { CourseStudentStatus, LESSON_ERROR, initLessons, isFirstLessonGone } from "@/utils/courses";

import _ from "lodash";
import { apolloClient } from "@/main";
import gql from "graphql-tag";

export interface EducationStore {
  courses: Api.EducationCourse[];
  students: Api.User[];
}

export const state: EducationStore = {
  courses: [],
  students: [],
};

export const getters = {};

export const mutations = {
  setCourses(state: EducationStore, courses: Api.EducationCourse[]) {
    state.courses = courses;
  },
  setStudents(state: EducationStore, students: Api.User[]) {
    state.students = students;
  },
  removeCourse(state: EducationStore, course: Api.EducationCourse) {
    state.courses = _.filter(state.courses, (c) => {
      return c.course_guid != course.course_guid;
    });
  },
};

export const actions = {
  async getCoursesByUser(
    context,
    data: { userGuid: string; onlyActive: boolean }
  ) {
    try {
      await context.commit("setCourses", []);
      const coursesRes = await apolloClient.query({
        query: require("@/graphql/getCoursesByUserList.graphql"),
        variables: {
          userGuid: data.userGuid,
        },
        fetchPolicy: "no-cache",
      });

      const courses = coursesRes.data.EducationCourseListByUser;
      if (data.onlyActive) {
        await context.commit(
          "setCourses",
          courses.filter((course) => {
            return true;
          })
        );
      } else {
        await context.commit(
          "setCourses",
          courses.filter((course) => {
            return true;
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getCoursesByStudent(
    context,
    data: { userGuid: string; availability: string[]; status: string[] }
  ) {
    try {
      await context.commit("setCourses", []);
      const coursesRes = await apolloClient.query({
        query: require("@/graphql/getCoursesByStudentList.graphql"),
        variables: {
          userGuid: data.userGuid,
        },
        fetchPolicy: "no-cache",
      });

      const courses = coursesRes.data.EducationCourseListByStudent;
      const filteredCourses = courses.filter((course) => {
        return (
          data.availability.includes(course.course_availability) &&
          data.status.includes(course.course_status)
        );
      });
      courses.forEach(course => {
        course.lessons = initLessons(course, course.lessons);
      });
      await context.commit("setCourses", filteredCourses);
      return filteredCourses;
    } catch (error) {
      console.error(error);
    }
  },
  async getCourses(
    context,
    data: { userGuid: string; availability: string[]; status: string[] }
  ) {
    try {
      await context.commit("setCourses", []);
      const coursesRes = await apolloClient.query({
        query: require("@/graphql/getCoursesList.graphql"),
        fetchPolicy: "no-cache",
      });

      const courses = coursesRes.data.EducationCourseList;
      const filteredCourses = courses.filter((course) => {
        return (
          data.availability.includes(course.course_availability) &&
          data.status.includes(course.course_status)
        );
      });
      
      await context.commit(
        "setCourses",
        filteredCourses
      );
    } catch (error) {
      console.error(error);
    }
  },
  async getCourse(context, data: { courseGuid: string }) {
    try {
      const courseRes = await apolloClient.query({
        query: require("@/graphql/getCourse.graphql"),
        variables: {
          courseGuid: data.courseGuid,
        },
        fetchPolicy: "no-cache",
      });

      const course = courseRes.data.EducationCourse;
      const lessons: Api.EducationLesson[] = course.lessons;
      course.lessons = initLessons(course, lessons);
      course.teachers = _.sortBy(course.teachers, 'name')

      return course;
    } catch (error) {
      console.error(error);
    }
  },
  async insertEducationStudent(
    context,
    data: { courseGuid: string; userGuid: string }
  ) {
    try {
      const educationStudentRes = await apolloClient.query({
        query: require("@/graphql/insertEducationStudent.graphql"),
        variables: {
          courseGuid: data.courseGuid,
          userGuid: data.userGuid,
        },
        fetchPolicy: "no-cache",
      });
    } catch (error) {
      console.error(error);
    }
  },
  async updateEducationStudentStatus(
    context,
    data: { courseGuid: string; userGuid: string; status: string }
  ) {
    try {
      const educationStudentRes = await apolloClient.query({
        query: require("@/graphql/updateEducationStudent.graphql"),
        variables: {
          courseGuid: data.courseGuid,
          userGuid: data.userGuid,
          status: data.status,
        },
        fetchPolicy: "no-cache",
      });
      return educationStudentRes.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  async getEducationStudent(
    context,
    data: { courseGuid: string; userGuid: string }
  ) {
    try {
      const educationStudentRes = await apolloClient.query({
        query: require("@/graphql/getEducationStudent.graphql"),
        variables: {
          courseGuid: data.courseGuid,
          userGuid: data.userGuid,
        },
        fetchPolicy: "no-cache",
      });

      const educationStudent: Api.EducationStudent =
        educationStudentRes.data.EducationStudent;
      return educationStudent;
    } catch (error) {
      console.error(error);
    }
  },
  async getCourseStudents(context, data: { courseGuid: string }) {
    try {
      await context.commit("setStudents", []);
      const studentsRes = await apolloClient.query({
        query: require("@/graphql/getCourseStudents.graphql"),
        variables: {
          courseGuid: data.courseGuid,
        },
        fetchPolicy: "no-cache",
      });
      await context.commit(
        "setStudents",
        studentsRes.data.EducationStudentListByCourse
      );
    } catch (error) {
      console.error(error);
    }
  },
  async checkLessonTimeAvailability(context, data: { startTime: string; duration: number }) {
    try {
      const checkRes = await apolloClient.query({
        query: require("@/graphql/educationMeetingCheckLessonFreeTimeWindow.graphql"),
        variables: {
          startTime: data.startTime,
          duration: data.duration
        },
        fetchPolicy: "no-cache",
      });
      return checkRes.data.EducationMeetingCheckLessonFreeTimeWindow != LESSON_ERROR;
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
