import i18n from './i18n';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import {apolloProvider} from './vue-apollo';
import Amplify from 'aws-amplify'
import awsconfig from './plugins/aws-exports'
import initMoment from './plugins/moment';

Amplify.configure(awsconfig)

Vue.config.productionTip = false;

import './styles/css/main.css';

initMoment();

export const apolloClient = apolloProvider.defaultClient

new Vue({
    router,
    store,
    vuetify,
    apolloProvider: apolloProvider,
    i18n,
    render: h => h(App),
}).$mount('#app');
