export enum EnvVars {
    VUE_APP_BE = 'VUE_APP_BE',
    VUE_APP_GOOGLE_MAPS_API_KEY = 'VUE_APP_GOOGLE_MAPS_API_KEY',
    VUE_APP_COGNITO_REGION = 'VUE_APP_COGNITO_REGION',
    VUE_APP_COGNITO_IDENTITY_POOL_ID = 'VUE_APP_COGNITO_IDENTITY_POOL_ID',
    VUE_APP_COGNITO_USER_POOLS_ID = 'VUE_APP_COGNITO_USER_POOLS_ID',
    VUE_APP_COGNITO_USER_POOLS_WEB_CLIENT_ID = 'VUE_APP_COGNITO_USER_POOLS_WEB_CLIENT_ID',
    VUE_APP_ENVIRONMENT = 'VUE_APP_ENVIRONMENT',
}

function getMetaProp(varName: string): string {
    return document.querySelector(`meta[property=${varName}]`)?.getAttribute('content');
}

export function getEnv(varName: EnvVars): string {
    if (process.env && process.env[`${varName}`] !== undefined) {
        return process.env[`${varName}`];
    } else {
        return getMetaProp(varName);
    }
}

export type Env = Record<EnvVars, string>;
let _env: Env;
export function env(): Env {
    if (!_env)
        _env = (() => {
            const acc = {};
            const keys = Object.keys(EnvVars);
            keys.forEach((k) => (acc[k] = getEnv(k as EnvVars)));
            return acc;
        })() as Env;
    return _env;
}

export function isNotProduction() {
    return env()['VUE_APP_ENVIRONMENT'] != 'production';
}

export function isProduction() {
    return env()['VUE_APP_ENVIRONMENT'] == 'production';
}
