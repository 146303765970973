import * as Api from "@/types/graphql";

import { RoleCodes } from "./roles";
import _ from "lodash";

export enum StatusCodes {
  // USER
  USER_DIP_AZ = "USER_DIP_AZ",
  USER_ACTIVE = "USER_ACTIVE",
  USER_FIRED = "USER_FIRED",
  USER_SLEEPY = "USER_SLEEPY",
  DELETED_USER = "DELETED_USER",
  USER_PASSIVE = "USER_PASSIVE",
  USER_REF_AZ = "USER_REF_AZ",
  SUSPENDED_USER = "SUSPENDED_USER",

  // ADMIN 1
  ADMIN1_ACTIVE = "ADMIN1_ACTIVE",
  DELETED_ADMIN1 = "DELETED_ADMIN1",
  SUSPENDED_ADMIN1 = "SUSPENDED_ADMIN1",

  // ADMIN 2
  DELETED_ADMIN2 = "DELETED_ADMIN2",
  ADMIN2_RES_AREA = "ADMIN2_RES_AREA",
  ADMIN2_RES_SER = "ADMIN2_RES_SER",
  SUSPENDED_ADMIN2 = "SUSPENDED_ADMIN2",
}

export enum ServiceStatus {
  REQUEST = "REQUEST",
  ASSIGNED = "ASSIGNED",
  DELETED = "DELETED",
}

export function checkUserStatus(
  user: Api.User,
  statusCodes: StatusCodes[]
): boolean {
  return _.includes(statusCodes, user.status?.code);
}

export function isCompanyReferent(code) {
  return code == StatusCodes.USER_REF_AZ;
}

export function isSuspended(code) {
  return (
    code == StatusCodes.SUSPENDED_USER ||
    code == StatusCodes.SUSPENDED_ADMIN1 ||
    code == StatusCodes.SUSPENDED_ADMIN2
  );
}

export function isDeleted(code) {
  return (
    code == StatusCodes.DELETED_USER ||
    code == StatusCodes.DELETED_ADMIN1 ||
    code == StatusCodes.DELETED_ADMIN2
  );
}

export function isFiredOrResigned(code) {
  return code == StatusCodes.USER_FIRED;
}

export function getDeletedStatusCode(roleCode: string): StatusCodes {
  switch (roleCode) {
    case RoleCodes.USER:
      return StatusCodes.DELETED_USER;
    case RoleCodes.ADMIN1:
      return StatusCodes.DELETED_ADMIN1;
    case RoleCodes.ADMIN2:
      return StatusCodes.DELETED_ADMIN2;
  }
}

export function getSuspendedStatusCode(roleCode: string): StatusCodes {
  switch (roleCode) {
    case RoleCodes.USER:
      return StatusCodes.SUSPENDED_USER;
    case RoleCodes.ADMIN1:
      return StatusCodes.SUSPENDED_ADMIN1;
    case RoleCodes.ADMIN2:
      return StatusCodes.SUSPENDED_ADMIN2;
  }
}

export function isRequested(service: Api.Service) {
  return service.status == ServiceStatus.REQUEST;
}

export function isAssigned(service: Api.Service) {
  return service.status == ServiceStatus.ASSIGNED;
}

export function isUserStatus(code: StatusCodes) {
  return code.includes("USER");
}

export function isAdmin1Status(code: StatusCodes) {
  return code.includes("ADMIN1");
}

export function isAdmin2Status(code: StatusCodes) {
  return code.includes("ADMIN2");
}
