
import gql from "graphql-tag";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Mutation } from "vuex-class";
import _ from "lodash";

@Component({
  watch: {
    scrollTop: function (newVal, oldVal) {
      if (newVal === true) {
        this.scrollToThird = false;
        this.scrollToSix = false;
        const element = document.getElementsByClassName(
          "modal-text-content"
        )[0];
        if (element) {
          element.scrollTop = 0;
        }
      }
    },
  },
})
export default class TermsModalContent extends Vue {
  @Prop()
  scrollTop: boolean;
  @Prop()
  modalShowed: boolean;
  @Prop()
  showButtonScroll: boolean;
  @Mutation showError;

  scrollToSix = false;
  scrollToThird = false;

  termContent = "";

  async mounted() {
    await this.getTerms();
  }

  async getTerms() {
    const termsRes = await this.$apollo.query({
      query: gql`
        query {
          TermCondition {
            guid
            accepted
            title
            content
          }
        }
      `,
      fetchPolicy: "no-cache",
    });
    if (_.isEmpty(termsRes.data.TermCondition)) {
      this.showError("Impossibile recuperare i Termini e Condizioni");
    } else {
      this.termContent = termsRes.data.TermCondition[0].content;
    }
  }

  scrollTo() {
    if (!this.scrollToThird) {
      document.getElementById("three-section").scrollIntoView();
      this.scrollToThird = true;
    } else if (!this.scrollToSix) {
      document.getElementById("six-section").scrollIntoView();
      this.scrollToSix = true;
    } else {
      const element = document.getElementsByClassName("modal-text-content")[0];
      if (element) {
        element.scrollTop = element.scrollHeight;
        this.$emit("notDisabled");
      }
    }
  }

  checkScroll() {
    const element = document.getElementsByClassName("modal-text-content")[0];
    if (
      element &&
      this.modalShowed &&
      element.scrollHeight - element.clientHeight === element.scrollTop
    ) {
      this.$emit("notDisabled");
    }
  }
}
