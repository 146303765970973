
import Vue from "vue";
import Component from "vue-class-component";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import { Message } from "../store/modules/message";

@Component({
  watch: {
    message(val, prev) {
      if (val) {
        setTimeout(() => this.removeMessage(), 4100);
      }
    },
  },
})
export default class MainAlert extends Vue {
  @State((state: StateStore) => state.message.message) message: Message;
  @Mutation removeMessage;

  getColor() {
    if (this.message) {
      return this.message.type;
    } else return "info";
  }

  getText() {
    if (this.message) {
      return this.message.text;
    } else return "";
  }

  getIcon(){
    if(this.isSuccess()){
      return 'mdi-check-circle-outline'
    }
    if(this.isError()){
      return 'mdi-alert-circle-outline'
    }
  }

  isSuccess() {
    return this.message?.type == "success";
  }

  isError() {
    return this.message?.type == "error";
  }

}
