import * as Api from "@/types/graphql";

import _ from "lodash";

export enum RoleCodes {
	ALL = '',
	USER = 'USER',
	ADMIN2 = 'ADMIN2',
	ADMIN1 = 'ADMIN1',
}

export function checkUserRole(user: Api.User, roleCodes: RoleCodes[]): boolean {
	return _.includes(roleCodes, user.role?.role_id)
}

export function isAdmin(user: Api.User){
	return checkUserRole(user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2])
}

export const User = RoleCodes.USER
