export interface UiStore {
	showDrawer: boolean;
	fillHeightOnMainContainer: boolean;
}

export const state: UiStore = {
	showDrawer: false,
	fillHeightOnMainContainer: false,
};

export const getters = {};

export const mutations = {
	toggleDrawer(state) {
		state.showDrawer = !state.showDrawer;
	},
	setFillHeightOnMainContainer(state, enabled) {
		state.fillHeightOnMainContainer = enabled;
	}
};

export const actions = {
};

export default {
	state,
	getters,
	mutations,
	actions,
};
