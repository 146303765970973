import { EnvVars, env } from '@/utils/env';

const awsmobile = {
    "aws_project_region": env()[EnvVars.VUE_APP_COGNITO_REGION],
    "aws_cognito_identity_pool_id": env()[EnvVars.VUE_APP_COGNITO_IDENTITY_POOL_ID],
    "aws_cognito_region": env()[EnvVars.VUE_APP_COGNITO_REGION],
    "aws_user_pools_id": env()[EnvVars.VUE_APP_COGNITO_USER_POOLS_ID],
    "aws_user_pools_web_client_id": env()[EnvVars.VUE_APP_COGNITO_USER_POOLS_WEB_CLIENT_ID],
    "oauth": {}
};

export default awsmobile;
