export interface MessageStore {
	message?: Message;
}

export interface Message {
	text: string;
	type: string;
}

export const state: MessageStore = {
	message: null
};

export const getters = {};

export const mutations = {
	showError(state, errorText: string) {
		state.message = {
			text: errorText,
			type: 'error'
		}
	},
	showSuccess(state, errorText: string) {
		state.message = {
			text: errorText,
			type: 'success'
		}
	},
	removeMessage(state) {
		state.message = null
	}
};

export const actions = {
};

export default {
	state,
	getters,
	mutations,
	actions,
};
