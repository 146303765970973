const en = {
    accept: "Accetto",
    accepted: "Accettato",
    addCompanyTitle: 'Inserisci Azienda',
    addCourse: "Aggiungi Corso",
    addStudentsTab: "Aggiungi Partecipanti",
    adminArea: 'area amministrazione',
    adminsLevel1Tab: 'Amministratori L1',
    adminsLevel2Tab: 'Amministratori L2',
    allRightsReserved: "Tutti i diritti riservati.",
    area: {
        addValue: "",
        label: "Area"
    },
    assignServicesToAdminsTitle: 'Associa servizi a Admin Liv.2',
    assignServicesToCompaniesTitle: 'Associa servizi alle imprese',
    associateArea: "area associato",
    associated: {
        addValue: "",
        label: "Associato",
        note: ""
    },
    associateResources: "Servizi CNA",
    birthday: {
        addValue: "aggiungi data di nascita",
        label: "Data di nascita",
        note: "La data di nascita completa è visibile solo ai recruiter ed ai business developer di Whead. Non verrà condiviso con altri professionisti iscritti a Whead. L’anno ed il mese di nascita può essere visibile a tutti nei vari prodotti Whead."
    },
    cancel: "Annulla",
    checkPrivacyNotice: "È necessario accettare la privacy policy per poter accedere",
    city: {
        addValue: "Aggiungi o modifica la città in cui risiedi abitualmente, può essere utile nel caso in cui un cliente chieda una presenza in loco oppure per sviluppare connessioni tra Wheader che lavorano su uno stesso progetto.",
        label: "Città di residenza",
        note: "La città in cui risiedi abitualmente può essere visibile a tutti nei vari prodotti Whead."
    },
    cityNotValid: "Inserire una città",
    cityOfResidence: "Città di residenza",
    companiesListTab: 'Elenco Aziende',
    company: {
        addValue: "Nessuna azienda associata",
        label: "Azienda",
        note: ""
    },
    companyAddress: {
        addValue: "",
        label: "Indirizzo sede legale",
        note: ""
    },
    companyAteco: {
        addValue: "",
        label: "Codice Ateco",
        note: ""
    },
    companyCity: {
        addValue: "",
        label: "Comune sede legale",
        note: ""
    },
    companyCode: {
        addValue: "",
        label: "Codice Azienda",
        note: ""
    },
    companyGroup: {
        addValue: "",
        label: "Gruppo di interesse",
        note: ""
    },
    companyInformations: "Informazioni aziendali",
    companyName: {
        addValue: "",
        label: "Ragione soc aziendale",
        note: ""
    },
    companyPec: {
        addValue: "",
        label: "PEC",
        note: ""
    },
    companyProvince: {
        addValue: "",
        label: "Provincia sede legale",
        note: ""
    },
    companySector: {
        addValue: "",
        label: "Settore",
        note: ""
    },
    companyTaxCode: {
        addValue: "",
        label: "CF impresa",
        note: ""
    },
    companyUnion: {
        addValue: "",
        label: "Unione nazionale",
        note: ""
    },
    companyVat: {
        addValue: "",
        label: "partita iva",
        note: ""
    },
    completeVersion: "versione completa",
    configCourse: "Configura Corso",
    contactCNA: "Contatta CNA",
    cookiePolicy: "Cookie policy",
    courseDescription: "Informazioni e descrizione corso",
    courseInfoTab: "Informazioni",
    courseLessonsTab: "Lezioni",
    coursesCalendar: "Calendario",
    coursesInProgress: "Corsi in svolgimento",
    coursesList: "Lista Corsi",
    courseStudentsTab: "Partecipanti",
    databaseCompaniesTitle: 'Database Aziende',
    deadlines: "Prossime Scadenze",
    deletedCoursesList: "Corsi Eliminati",
    educationArea: "Area Formazione",
    email: {
        addValue: "",
        label: "email"
    },
    emptyValue: "Non compilato",
    endedCoursesList: "Corsi Terminati",
    firstYearAssociated: "Data associazione",
    footerCopyright: "CNA™ - All rights reserved",
    footerTextPrivacy: "Privacy Policy",
    footerTextTerms: "Termini di servizio",
    forgotPasswordQuestion: "Password dimenticata?",
    forgotPasswordText: "Inserisci il tuo indirizzo email di recupero",
    furtherInformation: "ulteriori informazioni",
    gender: {
        addValue: "Inserisci il sesso",
        label: "Sesso",
        note: ""
    },
    groupActions: "Azioni di gruppo",
    headquarter: {
        addValue: "",
        label: "Sede di competenza"
    },
    home: "Home",
    infoVisibleToOthers: "Alcune informazioni potrebbero essere visibili ad altre persone che usano i servizi CNA.",
    joinRequest: "Richiedi Iscrizione",
    login: "Accedi",
    loginSubtitle: "Entra nel più grande team di professionisti digitali in Europa",
    logout: "esci",
    manageCompanies: 'Gestione Aziende',
    ManageLessones: "Gestione Lezioni",
    manageServices: 'Gestione Servizi',
    manageServicesCompaniesListTitle: 'Gestisci servizi',
    manageServicesTab: 'Gestisci servizi: Elenco imprese',
    manageServicesTitle: 'Gestione Servizi',
    manageUsers: 'Gestione Utenti',
    manageUsersSubtitle: 'In questa sezione puoi gestire gli utenti e i loro relativi permessi',
    manageUsersTitle: 'Gestione Utente (Ruoli e Stati)',
    moreInfo: "Ulteriori informazioni.",
    myCompanyTitle: "La mia Azienda",
    myCourses: "I miei Corsi",
    nameSurname: {
        addValue: "",
        label: "nome e cognome"
    },
    nextLessons: "Prossime Lezioni",
    otherInformations: "Altre Informazioni",
    pageNotFound: "pagina non trovata",
    pageNotFoundDescription: "la pagina ricercata non è al momento disponibile o è inesistente.",
    personalData: "dati personali",
    personalInformationsTitle: "Informazioni Personali",
    photo: "foto",
    privateCoursesTab: 'Corsi Riservati',
    profile: "Profilo",
    profileImage: {
        addValue: "",
        label: "foto profilo"
    },
    profileImageNote: "La foto del profilo è visibile a tutti nei vari prodotti Whead.",
    publicCoursesTab: 'Corsi Pubblici',
    referenceCna: {
        addValue: "",
        label: "Referente CNA"
    },
    requestsTab: 'Richieste',
    role: {
        addValue: "",
        label: "ruolo"
    },
    save: "Salva",
    search: "Cerca",
    selectProfileImage: "Trascina qui una foto del profilo o clicca qui per selezionare una foto dal tuo computer.",
    send: "Invia",
    services: {
        addValue: "",
        label: "Servizi",
        note: ""
    },
    subGroup: {
        addValue: "",
        label: "Sottogruppi"
    },
    suspendedDeletedTab: 'Sospesi ed Eliminati',
    switchLanguange: "cambia lingua",
    taxCode: {
        addValue: "Aggiungi o modifica il tuo codice fiscale.",
        label: "Codice Fiscale",
        note: ""
    },
    teacherDetails: 'Scheda Formatore',
    teachers: "Formatori",
    telephone: {
        addValue: "Aggiungi o modifica il tuo numero di telefono.",
        label: "Telefono",
        note: ""
    },
    termsAndConditionDrawer: "Termini di Servizio",
    termsAndConditionTitle: "Termini di Servizio Whead",
    termsSubtitle: "Per poter utilizzare i nostri servizi è necessario accettare i nostri termini e condizioni.",
    termsSummaryDescription: "Questa sezione riassume alcuni aspetti importanti dei termini di servizio di Whead, ma non sostituisce la ",
    trade: {
        addValue: "",
        label: "Mestiere"
    },
    useImageDescription: "l’utilizzo di una foto contribuisce a personalizzare il tuo account",
    userId: {
        addValue: "",
        label: "id utente"
    },
    userInformations: "Informazioni utente",
    userRole: "Ruolo utente",
    usersTab: 'Utenti',
    vat: "Partita IVA",
    vatNotValid: "Partita IVA non valida",
    vatSuggestion: "Usa il formato : IT123456789",
    yourCnaServices: "I tuoi servizi CNA",
    yourCompany: "La tua Azienda"
};
export default en;
