import VueRouter, { RouteConfig } from "vue-router";

import Drawer from "@/components/Drawer.vue";
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import Vue from "vue";
import { authGuard } from "./guards";
import { isProduction } from "@/utils/env";

Vue.use(VueRouter);

export const RoutesNames = {
  login: "login",
  signup: "signup",
  forgotPassword: "forgotPassword",
  profile: "profile",
  company: "company",
  profileEdit: "profileEdit",
  setPassword: "setPassword",
  termsCondition: "termsCondition",
  manageUsers: "manageUsers",
  manageUsersDetails: "manageUsersDetails",
  companiesAdd: "companiesAdd",
  companiesManage: "companiesManage",
  companiesManageDetails: "companiesManageDetails",
  yourServices: "yourServices",
  assignServicesToCompanies: "assignServicesToCompanies",
  assignServicesToAdmins: "assignServicesToAdmins",
  ServicesCompaniesList: "ServicesCompaniesList",
  courseAdd: "courseAdd",
  coursesList: "coursesList",
  myCoursesList: "myCoursesList",
  coursesDetails: "coursesDetails",
  teachersList: "teachersList",
  teachersDetails: "teachersDetails",
  calendar: "calendar",
  nextLessons: "nextLessons",
  help: "help",
};

const routes: Array<RouteConfig> = [
  { path: "/", name: "root", redirect: { name: "login" } },
  {
    path: "/login",
    name: RoutesNames.login,
    components: {
      default: () => import("@/views/LoginView.vue"),
    },
  },
  {
    path: "/signup",
    name: RoutesNames.signup,
    components: {
      default: () => import("@/views/SignupView.vue"),
    },
  },
  {
    path: "/forgot-password",
    name: RoutesNames.forgotPassword,
    components: {
      default: () => import("@/views/ForgotPasswordView.vue"),
    },
  },
  {
    path: "/set-password/:type",
    name: RoutesNames.setPassword,
    components: {
      default: () => import("@/views/SetPasswordView.vue"),
    },
  },
  {
    path: "/profile",
    name: RoutesNames.profile,
    components: {
      default: () => import("@/views/ProfileView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/profile/edit/:labelKey",
    name: RoutesNames.profileEdit,
    components: {
      default: () => import("@/views/ProfileEditView.vue"),
      top: Navbar,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/terms-and-conditions",
    name: RoutesNames.termsCondition,
    components: {
      default: () => import("@/views/TermsAndConditionView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/help",
    name: RoutesNames.help,
    components: {
      default: () => import("@/views/HelpView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
  },
  {
    path: "/your-company",
    name: RoutesNames.company,
    components: {
      default: () => import("@/views/CompanyView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/your-services",
    name: RoutesNames.yourServices,
    components: {
      default: () => import("@/views/YourServicesView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/manage-users",
    name: RoutesNames.manageUsers,
    components: {
      default: () => import("@/views/ManageUsersView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/manage-users/:userGuid",
    name: RoutesNames.manageUsersDetails,
    components: {
      default: () => import("@/views/ManageUsersDetailsView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/services/assign-to-companies",
    name: RoutesNames.assignServicesToCompanies,
    components: {
      default: () => import("@/views/AssignServicesToCompaniesView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/services/assign-to-admins",
    name: RoutesNames.assignServicesToAdmins,
    components: {
      default: () => import("@/views/AssignServicesToAdminsView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/services/companies",
    name: RoutesNames.ServicesCompaniesList,
    components: {
      default: () => import("@/views/ServicesCompaniesListView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/companies/add",
    name: RoutesNames.companiesAdd,
    components: {
      default: () => import("@/views/AddCompanyView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/companies/manage",
    name: RoutesNames.companiesManage,
    components: {
      default: () => import("@/views/DatabaseCompaniesView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/companies/companies/:companyGuid",
    name: RoutesNames.companiesManageDetails,
    components: {
      default: () => import("@/views/ManageCompaniesDetailsView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/education/add",
    name: RoutesNames.courseAdd,
    components: {
      default: () => import("@/views/AddCourseView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/education/courses",
    name: RoutesNames.coursesList,
    components: {
      default: () => import("@/views/CoursesListAdminView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/education/mycourses",
    name: RoutesNames.myCoursesList,
    components: {
      default: () => import("@/views/CoursesListView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/education/calendar",
    name: RoutesNames.calendar,
    components: {
      default: () => import("@/views/CoursesCalendarView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/education/next-lessons",
    name: RoutesNames.nextLessons,
    components: {
      default: () => import("@/views/NextLessonsView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/education/teachers",
    name: RoutesNames.teachersList,
    components: {
      default: () => import("@/views/ManageTeachersView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/education/teachers/:userGuid",
    name: RoutesNames.teachersDetails,
    components: {
      default: () => import("@/views/ManageUsersDetailsView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    props: {
      default: {
        isEducationSection: true,
      },
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/education/:courseGuid",
    name: RoutesNames.coursesDetails,
    components: {
      default: () => import("@/views/CourseDetailsView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "/deadlines",
    name: RoutesNames.company,
    components: {
      default: () => import("@/views/DeadlinesView.vue"),
      top: Navbar,
      left: Drawer,
      bottom: Footer,
    },
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
    },
  },
  {
    path: "*",
    components: {
      top: Navbar,
      left: Drawer,
      default: () => import("@/views/PageNotFoundView.vue"),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
