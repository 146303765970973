/* eslint @typescript-eslint/no-var-requires: "off" */
import * as Api from "@/types/graphql";

import { StatusCodes, checkUserStatus } from "@/utils/status";

import _ from "lodash";
import { apolloClient } from "@/main";
import gql from "graphql-tag";

export enum CompaniesListTypes {
  Complete,
  Database,
  Selector,
}

export interface DataStore {
  users: Api.User[];
  selectedUsers: Api.User[];
  selectedUsers2: Api.User[];
  candidateUsers: Api.CandidateUser[];
  selectedCandidateUsers: Api.CandidateUser[];
  company: Api.Company;
  companies: Api.Company[];
  companiesLoading: boolean;
  selectedCompanies: Api.Company[];
  areas: Api.CodeDecode[];
  selectedArea: Api.CodeDecode;
  services: Api.Service[];
  selectedServices: Api.Service[];
  selectedAteco: Api.Ateco[];
  selectedGroups: Api.CodeDecodeChild[]; //available groups are in the selectedArea
  selectedUnione: string[];
  headquarters: Api.Headquarter[];
  selectedHeadquarter: Api.Headquarter;
  companyReferents: Api.User[];
  companyEmployees: Api.User[];
  companyCNAReferents: Api.User[];
  companyServices: Api.CompanyService[];
  servicesSub: Api.ServiceSub[];
  selectedServicesSub: Api.ServiceSub[];
}

export const state: DataStore = {
  users: [],
  selectedUsers: [],
  selectedUsers2: [],
  candidateUsers: [],
  selectedCandidateUsers: [],
  company: null,
  companies: [],
  companiesLoading: false,
  selectedCompanies: [],
  areas: [],
  selectedArea: null,
  services: [],
  selectedServices: [],
  selectedAteco: [],
  selectedGroups: [],
  selectedUnione: [],
  headquarters: [],
  selectedHeadquarter: null,
  companyReferents: [],
  companyEmployees: [],
  companyCNAReferents: [],
  companyServices: [],
  servicesSub: [],
  selectedServicesSub: [],
};

export const getters = {
  getArea: (state) => (guids: string[]) => {
    return _.filter(state.areas, (area) => {
      return guids.includes(area.guid);
    });
  },
  getSubGroup: (state) => (guids: string[]) => {
    let groups = [];
    _.forEach(state.areas, (area) => {
      const areaGroup = _.filter(area["code_decode_child"], (group) => {
        return guids.includes(group.guid);
      });
      groups = _.concat(groups, areaGroup);
    });
    return groups;
  },
};

export const mutations = {
  setUsers(state: DataStore, users: Api.User[]) {
    state.users = users;
  },
  addSelectedUsers(state: DataStore, users: Api.User[]) {
    state.selectedUsers = _.unionBy(state.selectedUsers, users, "guid");
  },
  addSelectedUsers2(state: DataStore, users: Api.User[]) {
    state.selectedUsers2 = _.unionBy(state.selectedUsers2, users, "guid");
  },
  removeSelectedUser(state: DataStore, user: Api.User) {
    state.selectedUsers = _.filter(state.selectedUsers, (u) => {
      return u.guid != user.guid;
    });
  },
  removeSelectedUser2(state: DataStore, user: Api.User) {
    state.selectedUsers2 = _.filter(state.selectedUsers2, (u) => {
      return u.guid != user.guid;
    });
  },
  setSelectedUsers(state: DataStore, selectedUsers: Api.User[]) {
    state.selectedUsers = selectedUsers;
  },
  setSelectedUsers2(state: DataStore, selectedUsers: Api.User[]) {
    state.selectedUsers2 = selectedUsers;
  },
  resetSelectedUsers(state: DataStore) {
    state.selectedUsers = [];
  },
  resetSelectedUsers2(state: DataStore) {
    state.selectedUsers2 = [];
  },
  setCandidateUsers(state: DataStore, candidateUsers: Api.CandidateUser[]) {
    state.candidateUsers = candidateUsers;
  },
  addSelectedCandidateUsers(
    state: DataStore,
    candidateUsers: Api.CandidateUser[]
  ) {
    state.selectedCandidateUsers = _.unionBy(
      state.selectedCandidateUsers,
      candidateUsers,
      "guid"
    );
  },
  removeSelectedCandidateUser(state: DataStore, user: Api.CandidateUser) {
    state.selectedCandidateUsers = _.filter(
      state.selectedCandidateUsers,
      (u) => {
        return u.guid != user.guid;
      }
    );
  },
  setSelectedCandidateUsers(
    state: DataStore,
    selectedCandidateUsers: Api.CandidateUser[]
  ) {
    state.selectedCandidateUsers = selectedCandidateUsers;
  },
  resetSelectedCandidateUsers(state: DataStore) {
    state.selectedCandidateUsers = [];
  },
  setCompany(state: DataStore, company: Api.Company) {
    //console.log("company", company);
    state.company = company;
  },
  setCompaniesLoading(state: DataStore, loading: boolean) {
    state.companiesLoading = loading;
  },
  setCompanies(state: DataStore, companies: Api.Company[]) {
    state.companies = companies;
  },
  setHeadquarters(state: DataStore, headquarters: Api.Headquarter[]) {
    state.headquarters = headquarters;
  },
  setSelectedHeadquarter(state: DataStore, headquarter: Api.Headquarter) {
    state.selectedHeadquarter = headquarter;
  },
  setAreas(state: DataStore, areas: Api.CodeDecode[]) {
    state.areas = areas;
  },
  setSelectedArea(state: DataStore, area: Api.CodeDecode) {
    state.selectedArea = area;
  },
  setServices(state: DataStore, services: Api.Service[]) {
    state.services = services;
  },
  addSelectedServices(state: DataStore, services: Api.Service[]) {
    state.selectedServices = _.unionBy(
      state.selectedServices,
      services,
      "guid"
    );
  },
  removeSelectedService(state: DataStore, service: Api.Service) {
    state.selectedServices = _.filter(state.selectedServices, (ser) => {
      return ser.guid != service.guid;
    });
  },
  setSelectedServices(state: DataStore, selectedServices: Api.Service[]) {
    state.selectedServices = selectedServices;
  },
  resetSelectedServices(state: DataStore) {
    state.selectedServices = [];
  },
  addSelectedCompanies(state: DataStore, companies: Api.Company[]) {
    state.selectedCompanies = _.unionBy(
      state.selectedCompanies,
      companies,
      "guid"
    );
  },
  removeSelectedCompany(state: DataStore, company: Api.Company) {
    state.selectedCompanies = _.filter(state.selectedCompanies, (com) => {
      return com.guid != company.guid;
    });
  },
  setSelectedCompanies(state: DataStore, selectedCompanies: Api.Company[]) {
    state.selectedCompanies = selectedCompanies;
  },
  resetSelectedCompanies(state: DataStore) {
    state.selectedCompanies = [];
  },
  removeSelectedGroup(state: DataStore, group: Api.CodeDecodeChild) {
    state.selectedGroups = _.filter(state.selectedGroups, (g) => {
      return g.guid != group.guid;
    });
  },
  setSelectedGroups(state: DataStore, groups: Api.CodeDecodeChild[]) {
    state.selectedGroups = groups;
  },
  resetSelectedGroups(state: DataStore) {
    state.selectedGroups = [];
  },
  addSelectedAteco(state: DataStore, ateco: Api.Ateco[]) {
    state.selectedAteco = _.unionBy(state.selectedAteco, ateco, "code");
  },
  removeSelectedAteco(state: DataStore, ateco: Api.Ateco) {
    state.selectedAteco = _.filter(state.selectedAteco, (a) => {
      return a.code != ateco.code;
    });
  },
  setSelectedAteco(state: DataStore, selectedAteco: Api.Ateco[]) {
    state.selectedAteco = selectedAteco;
  },
  resetSelectedAteco(state: DataStore) {
    state.selectedAteco = [];
  },
  addSelectedUnione(state: DataStore, unione: string[]) {
    state.selectedUnione = _.union(state.selectedUnione, unione);
  },
  removeSelectedUnione(state: DataStore, unione: string) {
    state.selectedUnione = _.filter(state.selectedUnione, (u) => {
      return u != unione;
    });
  },
  setSelectedUnione(state: DataStore, selectedUnione: string[]) {
    state.selectedUnione = selectedUnione;
  },
  resetSelectedUnione(state: DataStore) {
    state.selectedUnione = [];
  },
  setCompanyReferents(state: DataStore, referents: Api.User[]) {
    state.companyReferents = referents;
  },
  setCompanyCNAReferents(state: DataStore, referents: Api.User[]) {
    state.companyCNAReferents = referents;
  },
  setCompanyEmployees(state: DataStore, employees: Api.User[]) {
    state.companyEmployees = employees;
  },
  setCompanyServices(state: DataStore, companyServices: Api.CompanyService[]) {
    state.companyServices = companyServices;
  },
  setServicesSub(state: DataStore, services: Api.ServiceSub[]) {
    _.forEach(services, (service) => {
      if (service.des == "Altro - specificare….") {
        service.des = "Altro";
      }
    });
    state.servicesSub = services;
  },
  addSelectedServicesSub(state: DataStore, servicesSub: Api.ServiceSub[]) {
    state.selectedServicesSub = _.unionBy(
      state.selectedServicesSub,
      servicesSub,
      "guid"
    );
  },
  removeSelectedServiceSub(state: DataStore, serviceSub: Api.ServiceSub) {
    state.selectedServicesSub = _.filter(state.selectedServicesSub, (ser) => {
      return ser.guid != serviceSub.guid;
    });
  },
  setSelectedServicesSub(
    state: DataStore,
    selectedServicesSub: Api.ServiceSub[]
  ) {
    state.selectedServicesSub = selectedServicesSub;
  },
  resetSelectedServicesSub(state: DataStore) {
    state.selectedServicesSub = [];
  },
};

export const actions = {
  async getUsers(
    context,
    data: {
      roleFilter: string[];
      statusFilter: StatusCodes[];
      isTeacher: boolean;
      return: boolean;
    }
  ) {
    try {
      if (!data.return) {
        await context.commit("setUsers", []);
      }
      const usersRes = await apolloClient.query({
        query: require("@/graphql/getUsersList.graphql"),
        variables: {
          roleGuid: data.roleFilter,
          isTeacher: data.isTeacher ? 1 : 0,
          pageIndex: 1,
          pageSize: 200,
        },
        fetchPolicy: "no-cache",
      });

      let users = usersRes.data.UserList;
      if (!_.isEmpty(data.statusFilter)) {
        users = _.filter(usersRes.data.UserList, (user) => {
          return checkUserStatus(user, data.statusFilter);
        });
      }

      if (data.return) {
        return users;
      } else {
        await context.commit("setUsers", users);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async fetchUser(context, guid: string) {
    try {
      const usersRes = await apolloClient.query({
        query: require("@/graphql/getUserByGuid.graphql"),
        variables: {
          guid: guid,
        },
        fetchPolicy: "no-cache",
      });
      return usersRes.data.User;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },
  async getCandidateUsers(context) {
    try {
      await context.commit("setCandidateUsers", []);
      const usersRes = await apolloClient.query({
        query: require("@/graphql/getCandidateUsersList.graphql"),
        variables: {
          pageIndex: 1,
          pageSize: 200,
        },
        fetchPolicy: "no-cache",
      });
      await context.commit(
        "setCandidateUsers",
        usersRes.data.CandidateUserList
      );
    } catch (error) {
      console.error(error);
    }
  },
  async fetchCompany(context, companyGuid) {
    try {
      const companyRes = await apolloClient.query({
        query: require("@/graphql/getCompany.graphql"),
        variables: {
          guid: companyGuid,
        },
        fetchPolicy: "no-cache",
      });
      await context.commit("setCompany", companyRes.data.Company);
      await context.commit(
        "setCompanyReferents",
        companyRes.data.Company.referents
      );
      await context.commit(
        "setCompanyEmployees",
        companyRes.data.Company.employees
      );
      const cnaReferents = [];
      _.forEach(companyRes.data.Company.referentsCNA, async (ref) => {
        const user = await context.dispatch("fetchUser", ref.referent_guid);
        cnaReferents.push(user);
      });
      await context.commit("setCompanyCNAReferents", cnaReferents);
    } catch (error) {
      console.error(error);
    }
  },
  async getCompany(context, companyGuid) {
    try {
      const companyRes = await apolloClient.query({
        query: require("@/graphql/getCompanyLight.graphql"),
        variables: {
          guid: companyGuid,
        },
        fetchPolicy: "no-cache",
      });
      return companyRes.data.Company;
    } catch (error) {
      console.error(error);
    }
  },
  async updateCompanyServices(
    context,
    data: { companies: string[]; services: string[]; status: string }
  ) {
    try {
      const companyRes = await apolloClient.mutate({
        mutation: gql`
          mutation updateCompanyService(
            $company: [String]
            $service: [String]
            $status: String
          ) {
            updateCompanyService(
              company: $company
              service: $service
              status: $status
            )
          }
        `,
        variables: {
          company: data.companies,
          service: data.services,
          status: data.status,
        },
        fetchPolicy: "no-cache",
      });
      return companyRes.data.updateCompanyService;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async sendEmailToCompanies(
    context,
    data: { companies: string[]; subject: string; text: string }
  ) {
    try {
      const sendEmailRes = await apolloClient.mutate({
        mutation: gql`
          mutation sendEmailCompany(
            $guid: [String]
            $subject: String
            $text: String
          ) {
            sendEmailCompany(guid: $guid, subject: $subject, text: $text)
          }
        `,
        variables: {
          guid: data.companies,
          subject: data.subject,
          text: data.text,
        },
        fetchPolicy: "no-cache",
      });
      return sendEmailRes.data.sendEmailCompany;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async getCompanies(
    context,
    data: {
      type: CompaniesListTypes;
      text: string;
      headquarter: string;
      unione: string[];
      ateco: string[];
      referent: string;
      associated: number;
      notAssociated: number;
      active: number;
      notActive: number;
      employees: number;
      notEmployees: number;
      pageIndex: number;
      pageSize: number;
    }
  ) {
    try {
      // CompaniesListTypes.Complete
      let query = require("@/graphql/getCompaniesList.graphql");
      if (data.type == CompaniesListTypes.Database) {
        query = require("@/graphql/getCompaniesDatabase.graphql");
      }
      if (data.type == CompaniesListTypes.Selector) {
        query = require("@/graphql/getCompaniesSelector.graphql");
      }

      if (data.associated == null) {
        data.associated = 1;
      }
      if (data.notAssociated == null) {
        data.notAssociated = 1;
      }

      if (data.active == null) {
        data.active = 1;
      }
      if (data.notActive == null) {
        data.notActive = 1;
      }

      if (data.employees == null) {
        data.employees = 1;
      }
      if (data.notEmployees == null) {
        data.notEmployees = 1;
      }

      await context.commit("setCompaniesLoading", true);
      await context.commit("setCompanies", []);

      let pageindex = 1;
      if (data.pageIndex != undefined) {
        pageindex = data.pageIndex;
      }
      let pagesize = 3000;
      if (data.pageSize != undefined) {
        pagesize = data.pageSize;
      }
      const pager = { pageindex, pagesize };

      const companiesRes = await apolloClient.query({
        query: query,
        variables: {
          text: data.text,
          headquarter: data.headquarter,
          unione: data.unione,
          ateco: data.ateco,
          referent: data.referent,
          associated: data.associated,
          notAssociated: data.notAssociated,
          active: data.active,
          notActive: data.notActive,
          employees: data.employees,
          notEmployees: data.notEmployees,
          pager: pager,
        },
        fetchPolicy: "no-cache",
      });
      await context.commit(
        "setCompanies",
        companiesRes.data.CompanyList.companies
      );
      await context.commit("setCompaniesLoading", false);
      return companiesRes.data.CompanyList.pager_tot_records;
    } catch (error) {
      console.error(error);
    }
  },
  async getHeadquarters(context) {
    try {
      await context.commit("setHeadquarters", []);
      const usersRes = await apolloClient.query({
        query: require("@/graphql/getHeadquartersList.graphql"),
        variables: {
          pageIndex: 1,
          pageSize: 200,
        },
        fetchPolicy: "no-cache",
      });
      await context.commit("setHeadquarters", usersRes.data.HeadquarterList);
    } catch (error) {
      console.error(error);
    }
  },
  async getAreas(context) {
    try {
      await context.commit("setAreas", []);
      const usersRes = await apolloClient.query({
        query: require("@/graphql/getAreasList.graphql"),
        fetchPolicy: "no-cache",
      });
      await context.commit("setAreas", usersRes.data.CodeDecodeList);
    } catch (error) {
      console.error(error);
    }
  },
  async getServices(context) {
    try {
      await context.commit("setServices", []);
      const usersRes = await apolloClient.query({
        query: require("@/graphql/getServicesList.graphql"),
        fetchPolicy: "no-cache",
      });
      await context.commit("setServices", usersRes.data.ServiceList);
    } catch (error) {
      console.error(error);
    }
  },
  async getServicesSub(context, data: { serviceGuid: string }) {
    try {
      await context.commit("setServicesSub", []);
      const res = await apolloClient.query({
        query: require("@/graphql/getServicesSubList.graphql"),
        fetchPolicy: "no-cache",
        variables: {
          serviceGuid: data.serviceGuid,
        },
      });

      await context.commit("setServicesSub", res.data.ServiceSubList);
    } catch (error) {
      console.error(error);
    }
  },
  async updateUsersServices(
    context,
    data: { users: string[]; services: string[]; status: string }
  ) {
    try {
      const updateUserServiceRes = await apolloClient.mutate({
        mutation: gql`
          mutation updateUserService(
            $user: [String]
            $service: [String]
            $status: String
          ) {
            updateUserService(user: $user, service: $service, status: $status)
          }
        `,
        variables: {
          user: data.users,
          service: data.services,
          status: data.status,
        },
        fetchPolicy: "no-cache",
      });
      return updateUserServiceRes.data.updateUserService;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async getCompanyServices(
    context,
    data: {
      companyGuid: string;
      serviceGuid: string;
      serviceSubGuid: string;
      from: string;
      to: string;
    }
  ) {
    console.log(data);
    try {
      await context.commit("setCompanyServices", []);
      const usersRes = await apolloClient.query({
        query: require("@/graphql/getCompanyServiceListByUserAndDate.graphql"),
        fetchPolicy: "no-cache",
        variables: {
          companyGuid: data.companyGuid,
          serviceGuid: data.serviceGuid,
          serviceSubGuid: data.serviceSubGuid,
          from: data.from ? data.from : "2000-01-01",
          to: data.to ? data.to : "2100-12-31",
        },
      });
      const companyServices = usersRes.data.CompanyServiceListByUserAndDate;
      console.log(companyServices);
      _.forEach(
        usersRes.data.CompanyServiceListByUserAndDate,
        (companyService) => {
          companyService.service = _.find(context.state.services, {
            guid: companyService.service_guid,
          });
        }
      );
      await context.commit("setCompanyServices", companyServices);
    } catch (error) {
      console.error(error);
    }
  },
  async getCompanyServicesById(
    context,
    data: {
      companyGuid: string;
      serviceGuid: string;
      serviceSubGuid: string;
      from: string;
      to: string;
    }
  ) {
    try {
      await context.commit("setCompanyServices", []);
      const usersRes = await apolloClient.query({
        query: require("@/graphql/getCompanyServiceListByCompany.graphql"),
        fetchPolicy: "no-cache",
        variables: {
          companyGuid: data.companyGuid,
          serviceGuid: data.serviceGuid,
          serviceSubGuid: data.serviceSubGuid,
          from: data.from ? data.from : '2000-01-01',
          to: data.to ? data.to : '2100-12-31',
        },
      });
      let companyServices = usersRes.data.CompanyServiceListByCompany;
      if (usersRes.data.CompanyServiceListByCompany == null) {
        companyServices = [];
      }
      console.log(companyServices);
      _.forEach(usersRes.data.CompanyServiceListByCompany, (companyService) => {
        companyService.service = _.find(context.state.services, {
          guid: companyService.service_guid,
        });
      });
      await context.commit(
        "setCompanyServices",
        _.sortBy(companyServices, 'service_guid')
      );
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
