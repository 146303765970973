import Vue from 'vue';
import VueI18n from 'vue-i18n';
import _ from "lodash";
import en from './en'
import enTerms from './en/terms'
import it from './it'
import itTerms from './it/terms'

Vue.use(VueI18n);

const ita = {
  ...it,
  ...itTerms,
}
const eng = {
  ...en,
  ...enTerms,
}

const messages = {
  it: ita,
  en: eng
};

// UNCOMMENT TO CHECK IF LANG OBJECTS ARE THE SAME

// const itaKeys = Object.keys(ita).sort();
// const engKeys = Object.keys(eng).sort();
// console.log("Are lang objects the same?")
// console.log(JSON.stringify(itaKeys) === JSON.stringify(engKeys));
// console.log("Differences:")
// console.log(_.difference(itaKeys, engKeys))

export const ENABLE_MULTI_LANG = false

export default new VueI18n({
  locale: 'it', // set locale
  messages, // set locale messages
})
