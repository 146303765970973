import { AUTH_TOKEN } from "@/vue-apollo";
import { Auth } from "aws-amplify";
import { Route } from "vue-router";
import { RoutesNames } from ".";
import _ from "lodash";
import store from "@/store";

const hasLeadToken = function (): boolean {

    return localStorage.getItem(AUTH_TOKEN) != null;
}

const hasCognitoToken = async function (): Promise<boolean> {

    const token = await (await Auth.currentSession()).getIdToken().getJwtToken();
    return token != null;
}

export const authGuard = async function (to: Route, from: Route, next, hide?: boolean) {
    let goNext = false;

    if (hide) {
        goNext = true;
        await store.dispatch('logout')
    }

    const accessibleToAll = [
        RoutesNames.profile,
        RoutesNames.profileEdit,
        RoutesNames.termsCondition
    ];

    // profile page is accessible from all users
    if (_.includes(accessibleToAll, to.name)) {
        if (hasLeadToken() || await hasCognitoToken()) {
            goNext = true;
            next();
        }
        else {
            goNext = true;
            await store.dispatch('logout')
        }
    }

    // all other cases are pages accessible only from Cognito users
    if (!goNext) {
        try {
            if (await hasCognitoToken()) {
                next();
            } else {
                await store.dispatch('logout')
            }
        } catch (error) {
            await store.dispatch('logout')
        }
    }


};