import i18n from "../i18n";
import moment from "moment";

export default function initMoment() {
  moment.locale(i18n.locale);
}

export function parseDate(date: string): moment.Moment {
  return moment(date, "DD/MM/YYYY");
}

export function getToday(): string {
  return moment().format("YYYY-MM-DD");
}

export function formatForApi(date: moment.Moment): string {
  return date.format("YYYY-MM-DD");
}

export function formatDateFromApi(date: string, format: string): string {
  if (date == null) return "Data Non Valida";
  const momentDate = moment(date, "YYYY-MM-DD");
  return momentDate.format(format);
}

export function parseDateFromApi(date: string): string {
  const momentDate = moment(date, "DD/MM/YYYY");
  return momentDate.format("YYYY-MM-DD");
}

export function isSameOrAfterToday(date: string) {
  if (date == null) return false;
  const momentDate = moment(date, "YYYY-MM-DD");
  return momentDate.isSameOrAfter(moment(), "day");
}
