import { ApolloLink, from } from "apollo-link";
import { EnvVars, env } from "@/utils/env";

import { ApolloClient } from "apollo-client";
import { Auth } from "aws-amplify";
import { InMemoryCache } from "apollo-cache-inmemory";
import { RetryLink } from "apollo-link-retry";
import Vue from "vue";
import VueApollo from "vue-apollo";
import _ from "lodash";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "apollo-link-error";
import { restartWebsockets } from "vue-cli-plugin-apollo/graphql-client";
import { setContext } from "apollo-link-context";
import store from "./store";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
export const AUTH_TOKEN = "apollo-token";

// Http endpoint
const httpEndpoint = `${env()[EnvVars.VUE_APP_BE]}/graphql`;

// HTTP Link
const httpLink: ApolloLink = createUploadLink({
  // createHttpLink({
  uri: httpEndpoint,
});

// Auth Link
const authLink: ApolloLink = setContext(async (operation, ctx) => {
  const { headers } = ctx;

  if (localStorage.getItem(AUTH_TOKEN) == null) {
    try {
      const token = await (await Auth.currentSession())
        .getIdToken()
        .getJwtToken();

      return {
        headers: {
          ...headers,
          "x-access-token": `${token}`,
        },
      };
    } catch (error) {
      return headers;
    }
  } else {
    const token = "whead_" + localStorage.getItem(AUTH_TOKEN);

    if (token)
      return {
        headers: {
          ...headers,
          "x-access-token": `${token}`,
        },
      };
  }
  return { headers };
});

// Retry Link
const retryLink: RetryLink = new RetryLink({
  // async attempts(count, operation, error): Promise<boolean> {
  //     const errorMessage = _.get(error, [
  //         'result',
  //         'errors',
  //         0,
  //         'extensions',
  //         'exception',
  //         'stacktrace',
  //         0,
  //     ]);
  //     // console.log('RetryLink:', count, operation, error, errorMessage);
  //     if (errorMessage && errorMessage.includes('TokenExpiredError')) {
  //         console.error('The id_token is expired');
  //         if ((await refreshTokensAndUser()).error) return false;
  //     } else if (errorMessage && errorMessage.includes('MutationWhileMimickingError')) {
  //         return false;
  //     }
  //     return count < 5;
  // },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
  delay: {
    initial: 100,
    max: 1000,
    jitter: true,
  },
});

// Error Link
function handleError({ message }, errorsToShow: string[]) {
  // const beCode = _.get(extensions, ['exception', 'extraInfo', 'code']);
  // switch (beCode) {
  //     case BeErrorCode.UNAUTHORIZED_ACCESS:
  //     case BeErrorCode.UNAUTHORIZED_ACCESS_CUSTOMER:
  //     case BeErrorCode.UNAUTHORIZED_ACCESS_USER:
  //         if (!_currentAuthentication().type) {
  //             globalRouter.push('/');
  //             signOut();
  //             return;
  //         }
  //         break;
  // }
  // if (beCode) {
  //     const translation = t(`beErrors.${beCode}`);
  //     message = !!translation && translation != '' ? `translation (${beCode})` : beCode;
  // }
  errorsToShow.push(message);
}
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (!_.isUndefined(networkError)) {
    const errors: string[] = [];
    if (graphQLErrors) graphQLErrors.map((e) => handleError(e, errors));
    else if (networkError) errors.push(networkError.message);

    if (networkError["statusCode"] == 401) {
      store.dispatch("logout");
    }
  }
  if (!_.isEmpty(graphQLErrors)) {
    if (graphQLErrors[0].path.includes("insertUser")) {
      if (
        graphQLErrors[0].message.includes("h_user.email") &&
        graphQLErrors[0].message.includes("Duplicate entry")
      ) {
        store.commit("showError", "Email già registrata");
      }
    }
  }
  //store.commit('showError', 'Errore generico')
});

// // Config
// const defaultOptions = {
//     // You can use `https` for secure connection (recommended in production)
//     httpEndpoint,
//     // You can use `wss` for secure connection (recommended in production)
//     // Use `null` to disable subscriptions
//     // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
//     wsEndpoint: null,
//     // LocalStorage token
//     tokenName: AUTH_TOKEN,
//     // Enable Automatic Query persisting with Apollo Engine
//     persisting: false,
//     // Use websockets for everything (no HTTP)
//     // You need to pass a `wsEndpoint` for this to work
//     websocketsOnly: false,
//     // Is being rendered on the server?
//     ssr: false,

//     // Override default apollo link
//     // note: don't override httpLink here, specify httpLink options in the
//     // httpLinkOptions property of defaultOptions.

//     // Override default cache
//     // cache: myCache

//     // Override the way the Authorization header is set
//     getAuth: () => localStorage.getItem(AUTH_TOKEN)
//     // Additional ApolloClient options
//     // apollo: { ... }

//     // Client local data (see apollo-link-state)
//     // clientState: { resolvers: { ... }, defaults: { ... } }
// };

export const apolloClient = new ApolloClient({
  link: from([errorLink, retryLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

// Call this in the Vue app file
// export function createProvider(options = {}) {
//     // Create apollo client
//     const { apolloClient /*wsClient*/ } = createApolloClient({
//         ...defaultOptions,
//         ...options,
//     });

//     // This gives type error, but for now we are not using websocket subscriptions anyway
//     // apolloClient.wsClient = wsClient;

//     // Create vue apollo provider
//     const apolloProvider = new VueApollo({
//         defaultClient: apolloClient,
//         defaultOptions: {
//             $query: {
//                 // fetchPolicy: 'cache-and-network',
//             },
//         },
//         errorHandler(error) {
//             // eslint-disable-next-line no-console
//             console.log(
//                 '%cError',
//                 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
//                 error.message
//             );
//         },
//     });

//     return apolloProvider;
// }

export const apolloProvider = new VueApollo({
  clients: {
    data: apolloClient,
  },
  defaultClient: apolloClient,
  // Watch loading state for all queries
  // See 'Smart Query > options > watchLoading' for detail
  // watchLoading(isLoading, countModifier) {
  // console.log('Global loading', isLoading, countModifier);
  //},
  // Global error handler for all smart queries and subscriptions
  async errorHandler(error) {
    console.log("this is the global apollo error handler:", error);
    window.alert(JSON.stringify(error));
  },
});

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.queryManager.fetchQueryRejectFns;
    await apolloClient.clearStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.queryManager.fetchQueryRejectFns;
    await apolloClient.clearStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}

export async function getSessionToken() {
  if (localStorage.getItem(AUTH_TOKEN) == null) {
    try {
      const token = await (await Auth.currentSession())
        .getIdToken()
        .getJwtToken();
      return token;
    } catch (e) {
      console.log("Error on get user amplify session");
    }
  } else {
    const token = "whead_" + localStorage.getItem(AUTH_TOKEN);
    return token;
  }
}
