import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes:{
            light: {
                primary: '#134B85',
                secondary: '#404040',
                accent: '#16BFFF',
                error:'#C71F27',
                success:'#38A751',
                warning: '#EF6C00',
            }
        }
    }
});
