
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Logo extends Vue {
  @Prop()
  logoClass: string;
  @Prop({ default: false })
  showTextWhite: boolean;
  @Prop({ default: false })
  showTextBlue: boolean;
  @Prop()
  textClass: string;
}
