
import Vue from "vue";
import Component from "vue-class-component";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import FileDrop from "@/components/FileDrop.vue";
import Card from "@/components/Card.vue";
import Btn from "@/components/Btn.vue";
import { Prop } from "vue-property-decorator";
import gql from "graphql-tag";
import { Action } from "vuex-class";

@Component({
  components: {
    Cropper,
    CircleStencil,
    FileDrop,
    Card,
    Btn
  }
})
export default class ProfileAvatarDialog extends Vue {
  @Action getUser;

  avatarImgString = null;
  avatarImgName = "";
  avatarImgRules = [
    value =>
      !value || value.size < 2000000 || "Avatar size should be less than 2 MB!"
  ];

  onClose() {
    this.avatarImgString = null;
    this.$emit("close");
  }

  async save() {
    const { canvas } = (this.$refs.cropper as Cropper).getResult();
    canvas.toBlob(result => {
      this.upload(result);
    });
  }

  async upload(blob) {
    const file = this.blobToFile(blob, this.avatarImgName);
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($file: Upload!) {
          userPhotoUpload(file: $file) {
            filename
            mimetype
            encoding
          }
        }
      `,
      variables: { file }
    });
    if (res.data?.userPhotoUpload != undefined) {
      this.$store.state.auth.user.photo = ""
      await this.getUser();
      this.$emit("close");
      this.avatarImgString = null;
    }
  }

  blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return theBlob as File;
  }
}
