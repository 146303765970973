
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import * as Api from "@/types/graphql";
import { State as StateStore } from "../store";
import { State } from "vuex-class";
import _ from "lodash";
import { env, EnvVars } from "@/utils/env";

@Component({})
export default class Avatar extends Vue {
  @Prop({ default: false })
  user: Api.User;
  @Prop({ default: false })
  showLabel: boolean;
  @Prop()
  labelClass: string;
  @Prop({ default: false })
  editable: boolean;

  onRightClick(e) {
    e.preventDefault();
  }

  withLabel() {
    return this.areNameAndSurnameValid() && this.showLabel;
  }

  areNameAndSurnameValid() {
    return (
      !_.isUndefined(this.user?.name) && !_.isUndefined(this.user?.surname)
    );
  }

  getNameSurnameInitials() {
    if (this.areNameAndSurnameValid()) {
      return this.user.name.charAt(0);
    }
    return "";
  }

  getNameSurname() {
    if (this.areNameAndSurnameValid()) {
      return this.user.name + " " + this.user.surname;
    }
    return "";
  }

  hasImage() {
    if (this.user == null) {
      return false;
    } else {
      const re = /(?:\.([^.]+))?$/;
      const ext = re.exec(this.user.photo ? this.user.photo : "")[1];
      return (
        ext && !_.isUndefined(this.user?.photo) && this.user.photo?.length > 0
      );
    }
  }

  getUserImage() {
    if (this.user.photo?.trim() != "") {
      return (
        env()[EnvVars.VUE_APP_BE] +
        "/user/image/download/" +
        this.user.photo?.trim().replace("/user/image/download/", "")
      );
    } else return undefined;
  }
}
