import * as _ from "lodash";

import { EducationCourse, EducationLesson } from "@/types/graphql";

import moment from "moment";

export const LESSON_ERROR = "-1";

export enum CourseAvailability {
  Public = "public",
  Private = "private",
}

export const courseAvailabilityOptions = [
  {
    label: "Pubblico",
    value: CourseAvailability.Public,
  },
  {
    label: "Privato",
    value: CourseAvailability.Private,
  },
];

export function getAvailabilityLabel(value: string): string {
  const availability = courseAvailabilityOptions.find((availability) => {
    return availability.value == value;
  });
  if (availability) {
    return availability.label;
  } else {
    return "";
  }
}

export enum CourseType {
  Online = "online",
  Meetingroom = "meetingroom",
  Other = "other",
}

export const courseTypeOptions = [
  {
    label: "Online / Misto",
    value: CourseType.Online,
  },
  {
    label: "In aula",
    value: CourseType.Meetingroom,
  },
  {
    label: "Altra sede",
    value: CourseType.Other,
  },
];

export function getCourseTypeLabel(value: string): string {
  const type = courseTypeOptions.find((type) => {
    return type.value == value;
  });
  if (type) {
    return type.label;
  } else {
    return "";
  }
}

export enum CourseStatus {
  Published = "published",
  WorkInProgress = "workinprogress",
  Ended = "ended",
  Deleted = "deleted",
}

export enum CourseStudentStatus {
  waiting = "waiting",
  subscribed = "subscribed",
  revoked = "revoked",
  denied = "denied",
  abandoned = "abandoned",
  completed = "completed",
}

export function getCourseStudentStatusLabel(status: CourseStudentStatus) {
  switch (status) {
    case CourseStudentStatus.waiting:
      return "In attesa";
    case CourseStudentStatus.subscribed:
      return "Iscritto";
    case CourseStudentStatus.revoked:
      return "Revocata";
    case CourseStudentStatus.denied:
      return "Rifiutata";
    case CourseStudentStatus.abandoned:
      return "Abbandonato";
    case CourseStudentStatus.completed:
      return "Completato";
  }
}

export function mapCourseFromApi(course: EducationCourse) {
  return {
    guid: course.course_guid,
    owner: course.owner_guid,
    name: course.course_title,
    availability: course.course_availability,
    area: course.course_subjectarea,
    classes: course.course_lessons,
    hours: course.course_hours,
    minPeople: course.course_students_min,
    maxPeople: course.course_students_max,
    type: course.course_type,
    link: course.course_link,
    classroom: course.course_room,
    address: course.course_address,
    location: course.course_location,
    teachers: course.teachers,
    lessons: course.lessons,
    description: course.course_info,
    moreInfo: course.course_info_more,
  };
}

export function getStudentStatus(course: EducationCourse, userGuid: string) {
  if (course.students == null) return "";
  if (course.students == []) return "";

  const student = _.find(course.students, { guid: userGuid });
  if (student) {
    return student.course_student_status;
  } else return "";
}

export function userIsSubscribed(course: EducationCourse, userGuid: string) {
  return getStudentStatus(course, userGuid) == CourseStudentStatus.subscribed;
}

export function canUserRequestSubscription(
  course: EducationCourse,
  userGuid: string
) {
  return getStudentStatus(course, userGuid) == "";
}

export function getStudentStatusLabel(
  course: EducationCourse,
  userGuid: string
) {
  return getCourseStudentStatusLabel(
    getStudentStatus(course, userGuid) as CourseStudentStatus
  );
}

export function getStudentStatusColorClass(status: CourseStudentStatus) {
  switch (status) {
    case CourseStudentStatus.waiting:
      return "warning--text";
    case CourseStudentStatus.subscribed:
      return "success--text";
    case CourseStudentStatus.revoked:
      return "error--text";
    case CourseStudentStatus.denied:
      return "error--text";
    case CourseStudentStatus.abandoned:
      return "error--text";
    case CourseStudentStatus.completed:
      return "success--text";
  }
}

export function isFirstLessonGone(course: EducationCourse) {
  if (course.lessons == null) return false;
  if (course.lessons == []) return false;

  const firstLessonDate = moment(course.lessons[0].lesson_date, "YYYY-MM-DD");
  return firstLessonDate.isBefore(moment(), "day");
}

export function getCourseDuration(course) {
  if (course.lessons == null) return 0;
  if (course.lessons == []) return 0;

  let courseDuration = moment.duration("00:00");

  course.lessons.forEach((lesson) => {
    if (_.has(lesson, "startTime")) {
      lesson["lesson_from"] = lesson.startTime + ":00";
    }
    if (_.has(lesson, "endTime")) {
      lesson["lesson_to"] = lesson.endTime + ":00";
    }
    const start = moment(lesson.lesson_from, "HH:mm:ss");
    const end = moment(lesson.lesson_to, "HH:mm:ss");
    const difference = end.diff(start);
    const lessonDuration = moment.duration(difference);
    const lessonDurationString =
      Math.floor(lessonDuration.asHours()) +
      moment.utc(difference).format(":mm");

    courseDuration = courseDuration.add(lessonDurationString);
  });

  return moment.utc(courseDuration.as("milliseconds")).format("HH:mm");
}

export function initLessons(
  course: EducationCourse,
  lessons: EducationLesson[]
) {
  lessons.forEach((lesson) => {
    if (!_.isEmpty(lesson["lesson_from"])) {
      lesson["lesson_from"] = lesson["lesson_from"].slice(0, -3);
    }
    if (!_.isEmpty(lesson["lesson_to"])) {
      lesson["lesson_to"] = lesson["lesson_to"].slice(0, -3);
    }
    lesson["teachers"] = [];
    course.teachers?.forEach((teacher) => {
      if (lesson.teacher_guid.includes(teacher.guid)) {
        lesson["teachers"].push(teacher);
      }
    });
    lesson['teacher_guid'] = lesson.teacher_guid.filter((g) => {return !_.isEmpty(g)})
  });
  return _.sortBy(lessons, ["lesson_date", "lesson_from"]);
}
