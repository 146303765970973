
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from "vue";
import { ENABLE_MULTI_LANG } from "@/i18n";
@Component({})
export default class LangSelect extends Vue {
  @Prop({ default: 'white' })
  color: string;

  lang = this.$i18n.locale.toUpperCase();
  langList = ["it", "en"];

  switchLanguage(item) {
    if(item === "en" && this.$i18n.locale !== "en"){
      this.$i18n.locale = "en"
    } else if(item === "it" && this.$i18n.locale !== "it") {
      this.$i18n.locale = "it";
    }
    this.lang = item;
  }

  isMultiLangEnabled(){
    return ENABLE_MULTI_LANG;
  }

}
