
import DrawerListItem from "@/components/DrawerListItem.vue";
import * as Api from "@/types/graphql";
import { isNotProduction } from "@/utils/env";
import { checkUserStatus, StatusCodes } from "@/utils/status";
import Vue from "vue";
import Component from "vue-class-component";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import { checkUserRole, RoleCodes } from "../utils/roles";
import _ from "lodash";

@Component({
  components: {
    DrawerListItem,
  },
  watch: {
    showDrawer(val, prev) {
      this.show = !this.$refs.drawer.value;
    },
  },
  computed: {
    clipped() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
    temporary() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
  },
})
export default class Drawer extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.ui.showDrawer) showDrawer: boolean;
  @Mutation toggleDrawer;

  show = null;
  isShown = false;

  admin1Role = RoleCodes.ADMIN1;
  admin2Role = RoleCodes.ADMIN2;
  userRole = RoleCodes.USER;

  manageCompaniesSubItems = [
    { label: this.$t("addCompanyTitle"), to: "/companies/add" },
    { label: this.$t("databaseCompaniesTitle"), to: "/companies/manage" },
  ];

  educationAdminSubItems = [
    { label: this.$t("coursesList"), to: "/education/courses" },
    { label: this.$t("nextLessons"), to: "/education/next-lessons" },
    { label: this.$t("coursesCalendar"), to: "/education/calendar" },
    { label: this.$t("addCourse"), to: "/education/add" },
    { label: this.$t("teachers"), to: "/education/teachers" },
  ];

  educationSubItems = [
    { label: this.$t("coursesList"), to: "/education/mycourses" },
    { label: this.$t("nextLessons"), to: "/education/next-lessons" },
    { label: this.$t("coursesCalendar"), to: "/education/calendar" },
  ];

  getCCIAALogo() {
    return require("../assets/images/logo_CCIAA.png");
  }

  getManageServicesSubItems() {
    if (checkUserRole(this.user, [RoleCodes.ADMIN1])) {
      return [
        // {
        //   label: this.$t("assignServicesToCompaniesTitle"),
        //   to: "/services/assign-to-companies",
        // },
        {
          label: this.$t("assignServicesToAdminsTitle"),
          to: "/services/assign-to-admins",
        },
        // {
        //   label: this.$t("manageServicesCompaniesListTitle"),
        //   to: "/services/companies",
        // },
      ];
    }
    // if (checkUserRole(this.user, [RoleCodes.ADMIN2])) {
    //   return [
    //     {
    //       label: this.$t("assignServicesToCompaniesTitle"),
    //       to: "/services/assign-to-companies",
    //     },
    //     {
    //       label: this.$t("manageServicesCompaniesListTitle"),
    //       to: "/services/companies",
    //     },
    //   ];
    // }
  }

  showMenuItem(roleCodes: RoleCodes[]) {
    return checkUserRole(this.user, roleCodes);
  }

  isCompanyRef() {
    return checkUserStatus(this.user, [StatusCodes.USER_REF_AZ]);
  }

  isAssociated() {
    return this.user.associated;
  }

  hasCompany() {
    return !_.isNil(this.user.company);
  }

  isNotProduction() {
    return isNotProduction();
  }
}
