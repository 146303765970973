
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import LangSelect from "@/components/LangSelect.vue";
import Logo from "@/components/Logo.vue";
import Modal from "@/components/Modal.vue";
import ProfileAvatarDialog from "@/components/ProfileAvatarDialog.vue";
import TermsModalContent from "@/components/TermsModalContent.vue";
import * as Api from "@/types/graphql";
import { StatusCodes } from "@/utils/status";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import { checkUserRole, RoleCodes } from "../utils/roles";

@Component({
  components: {
    Avatar,
    Card,
    LangSelect,
    ProfileAvatarDialog,
    TermsModalContent,
    Modal,
    Btn,
    Logo,
  },
})
export default class Navbar extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Action logout;
  @Action getUser;
  @Action getAreas;
  @Action getServices;
  @Action getHeadquarters;
  @Action checkTerms;
  @Action fetchRolesList;
  @Action fetchStatusList;
  @Mutation toggleDrawer;
  @Prop({ default: true })
  showHamburger: boolean;
  showAvatarDialog = false;
  disabled = false;

  showContent = false;

  termsModal = null;

  async mounted() {
    this.$store.state.initialized = false;
    await this.getUser();
    await this.fetchRolesList();
    await this.fetchStatusList(RoleCodes.ALL);
    await this.getAreas();
    await this.getServices();
    await this.getHeadquarters();
    this.$store.state.initialized = true;
    this.showContent = true;

    const termsAccepted = await this.checkTerms();
    if (!termsAccepted) {
      this.termsModal = true;
    }
  }

  getRole() {
    return this.user.role?.des ? this.user.role?.des : "";
  }

  getBackgroundColor() {
    if (checkUserRole(this.user, [RoleCodes.ADMIN1])) {
      return "white";
    } else if (checkUserRole(this.user, [RoleCodes.ADMIN2])) {
      return "#129ACC";
    } else return "primary";
  }

  getContentColor() {
    if (checkUserRole(this.user, [RoleCodes.ADMIN1])) {
      return "primary";
    } else if (checkUserRole(this.user, [RoleCodes.ADMIN2])) {
      return "white";
    } else return "white";
  }

  getTextColor() {
    if (checkUserRole(this.user, [RoleCodes.ADMIN1])) {
      return "primary--text";
    } else if (checkUserRole(this.user, [RoleCodes.ADMIN2])) {
      return "white--text";
    } else return "white--text";
  }

  drawerExists() {
    return !_.isUndefined(this.$route.matched[0].components.left);
  }

  isUser() {
    return this.user.role?.role_id == RoleCodes.USER;
  }

  async confirm() {
    this.disabled = true;
    await this.$apollo.mutate({
      mutation: gql`
        mutation user_update($guid: ID!, $termConditionAccepted: Int) {
          updateUser(
            guid: $guid
            term_condition_accepted: $termConditionAccepted
          )
        }
      `,
      variables: {
        guid: this.user.guid,
        termConditionAccepted: 1,
      },
    });
    this.disabled = false;
    this.termsModal = null;
  }

  openWhatsapp(){
    window.open("https://wa.me/message/72UWJHSQ7F2ME1", '_blank');
  }
}
