
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({
  computed: {
    label() {
      return this.$t(this.labelKey);
    },
  },
  watch: {
    $route(to, from) {
      this.toggleActiveGroup(to.path);
    },
  },
})
export default class DrawerListItem extends Vue {
  @Prop()
  labelKey: string;
  @Prop()
  icon: string;
  @Prop()
  to: string;

  active = false;

  @Prop({
    default: () => {
      return [];
    },
  })
  subItems: [{ label: string; to: string }];
  @Prop({ default: "" })
  groupPath: string;

  mounted() {
    this.toggleActiveGroup(this.$route.path);
  }

  onClick() {
    this.$router.push({ path: this.subItems[0].to });
    this.active = true;
  }

  toggleActiveGroup(path) {
    if (this.$refs["group"] != undefined) {
      this.active = path.includes(this.groupPath);
      this.$refs["group"]["isActive"] = this.active;
    }
  }
}
