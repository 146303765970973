
import Vue from "vue";
import { mapState } from "vuex";
import { State } from "./store";
import MainAlert from "@/components/MainAlert.vue";

export default Vue.extend({
  name: "App",

  components: { MainAlert },
  computed: {
    ...mapState({
      fillHeightOnMainContainer: (state: State) =>
        state.ui.fillHeightOnMainContainer,
    }),
    ...mapState({
      initialized: (state: State) => state.initialized,
    }),
  },
});
